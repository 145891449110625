import React, {useState, useEffect} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {graphql, Link} from "gatsby";
import {IJobs, IFilterType, IAllItems, ISelectedItems} from "../models/jobs";
import JobListItemDesktop from "../components/jobs/jobs-list-item-desktop";
import JobListItemMobile from "../components/jobs/jobs-list-item-mobile";
import Box from "../components/jobs/jobs-filter-box";
import isPrevDate from "../utils/isPrevDate";
import {IFilterItem} from "../models/common";
import isUniqueFilterItems from "../utils/getUniqueFilterItems";

// type filterToggleObjType = { [key: FiltersStringsType]: boolean };
const offFilters: IFilterType = {
  commonFilter: false,
  department: false,
  company: false,
  location: false,
};
const today = new Date();

const JobsPage = (props) => {
  const {
    data: {
      allStrapiJobs: {nodes},
    },
  } = props;
  const jobs = nodes as Array<IJobs>;
  const [filtersToggle, setFiltersToggle] = useState<IFilterType>(offFilters);

  const [filters, setFilters] = useState<IAllItems>({
    departments: [],
    companies: [],
    locations: [],
  });

  const [filteredJobs, setFilteredJobs] = useState<Array<IJobs>>([]);
  const [initialJobs] = useState<Array<IJobs>>(
    (nodes as Array<IJobs>).filter(
      (job) => !job.expirationDate || !isPrevDate(job.expirationDate)
    )
  );

  useEffect(() => {
    //on initial load fill in the filters and default filtered jobs
    setFilteredJobs(initialJobs);
    const departments = initialJobs
      .map((job) => ({
        value: job.department.name,
        isSelected: false,
      }))
      .filter(isUniqueFilterItems);
    const companies = initialJobs
      .map((job) => ({
        value: job.startup.name,
        isSelected: false,
      }))
      .filter(isUniqueFilterItems);
    const locations = initialJobs
      .map((job) => ({
        value: job.location.location,
        isSelected: false,
      }))
      .concat({
        value: "Remote",
        isSelected: false,
      })
      .filter(isUniqueFilterItems);
    setFilters({departments, companies, locations});
  }, [nodes]);

  useEffect(() => {
    //filter jobs based on change in departments,companies,locations
    const selectedDepartments = filters.departments
      .filter((dept) => dept.isSelected)
      .map((dept) => dept.value);
    const selectedCompanies = filters.companies
      .filter((cmp) => cmp.isSelected)
      .map((cmp) => cmp.value);
    const selectedLocations = filters.locations
      .filter((loc) => loc.isSelected)
      .map((loc) => loc.value);

    if (
      selectedDepartments.length ||
      selectedCompanies.length ||
      selectedLocations.length
    ) {
      const tmpFilteredJobs = initialJobs.filter(
        (job) =>
          (selectedDepartments.length
            ? selectedDepartments.includes(job.department.name)
            : true) &&
          (selectedCompanies.length
            ? selectedCompanies.includes(job.startup.name)
            : true) &&
          (selectedLocations.length
            ? (selectedLocations.includes("Remote") && job.remote) ||
              selectedLocations.includes(job.location.location)
            : true)
      );

      setFilteredJobs(tmpFilteredJobs);
    } else {
      setFilteredJobs(initialJobs);
    }
  }, [filters]);

  const toggleFilter = (filterObj: Partial<IFilterType>) => {
    setFiltersToggle({
      ...offFilters,
      ...filterObj,
    });
  };

  const filerItems = (filterItems: IFilterItem[], selectedItems: string[]) => {
    const selectedFilterItems: IFilterItem[] = filterItems.map((itm) =>
      selectedItems.includes(itm.value)
        ? {...itm, isSelected: true}
        : {...itm, isSelected: false}
    );
    return selectedFilterItems;
  };
  const updateFilters = (
    items: string[],
    filter: "department" | "company" | "location"
  ) => {
    switch (filter) {
      case "department":
        const departments: IFilterItem[] = filerItems(
          filters.departments,
          items
        );
        setFilters({...filters, departments});
        break;
      case "company":
        const companies: IFilterItem[] = filerItems(filters.companies, items);
        setFilters({...filters, companies});

        break;
      case "location":
        const locations: IFilterItem[] = filerItems(filters.locations, items);
        setFilters({...filters, locations});
        break;
    }
  };

  const updateCommonFilters = (items: ISelectedItems) => {
    const departments: IFilterItem[] = filerItems(
      filters.departments,
      items.departments
    );

    const companies: IFilterItem[] = filerItems(
      filters.companies,
      items.companies
    );

    const locations: IFilterItem[] = filerItems(
      filters.locations,
      items.locations
    );

    setFilters({departments, companies, locations});
  };

  return (
    <Layout>
      <SEO title="Careers" description="Join an extraordinary team now" />
      <div className="py-8 w-full">
        <h1 className="text-4xl md:text-5xl font-bold mb-8 md:mb-12">
          Join an extraordinary team{" "}
          <span className="underline-primary">now</span>{" "}
        </h1>
        <div className="md:hidden">
          <Box
            onClick={() =>
              toggleFilter({
                commonFilter: !filtersToggle.commonFilter,
              })
            }
            text="Filters"
            selected={filtersToggle.commonFilter}
            className="md:hidden"
            allFilterItems={filters}
            onCommonFilterApplySelection={(items) => {
              updateCommonFilters(items);
              toggleFilter({commonFilter: false});
            }}
            closeBox={() => toggleFilter({commonFilter: false})}
          />
        </div>
        <div className="hidden md:flex md:mb-17  justify-center">
          <Box
            onClick={() =>
              toggleFilter({department: !filtersToggle.department})
            }
            text="Department"
            selected={filtersToggle.department}
            className="border-r-0"
            specificFilterItems={filters.departments}
            onApplySelection={(items) => {
              updateFilters(items, "department");
              toggleFilter({department: false});
            }}
            closeBox={() => toggleFilter({department: false})}
          />{" "}
          <Box
            onClick={() => toggleFilter({company: !filtersToggle.company})}
            text="Company"
            selected={filtersToggle.company}
            className="border-r-0"
            specificFilterItems={filters.companies}
            onApplySelection={(items) => {
              updateFilters(items, "company");
              toggleFilter({company: false});
            }}
            closeBox={() => toggleFilter({department: false})}
          />{" "}
          <Box
            onClick={() => toggleFilter({location: !filtersToggle.location})}
            text="Location"
            selected={filtersToggle.location}
            specificFilterItems={filters.locations}
            onApplySelection={(items) => {
              updateFilters(items, "location");
              toggleFilter({location: false});
            }}
            closeBox={() => toggleFilter({department: false})}
          />
        </div>
        <div className="flex flex-col">
          {filteredJobs.map((job) => (
            <>
              <JobListItemDesktop key={`${job.id}-desktop`} job={job} />
              <JobListItemMobile key={`${job.id}-mobile`} job={job} />
            </>
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default JobsPage;

export const pageQuery = graphql`
  query getJobs {
    allStrapiJobs(sort: {fields: createdAt, order: DESC}) {
      nodes {
        id
        industry {
          name
        }
        learnMoreLink
        location {
          location
        }
        published
        remote
        title
        department {
          name
        }
        expirationDate
        startup {
          name
          slug
          logo {
            # childImageSharp {
            #   fluid(maxWidth: 300) {
            #     ...GatsbyImageSharpFluid
            #   }
            # }
            publicURL
          }
        }
      }
    }
  }
`;
