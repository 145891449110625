import React, { useState } from "react";
import { IAllItems, ISelectedItems } from "../../models/jobs";
import Button from "../button";
import useFilterItems from "../../hooks/use-filter-items";
import FilterItemsList from "../filter-items-list";
const close = require("../../images/close.svg");

const CommonFilter: React.FC<{
  items: IAllItems;
  onApplySelection: (items: ISelectedItems) => void;
  onClose?: () => void;
}> = ({ items, onApplySelection, onClose }) => {
  const [
    selectedDepartments,
    onDepartmentsChange,
    clearDepartments,
  ] = useFilterItems(items.departments);
  const [selectedCompanies, onCompaniesChange, clearCompanies] = useFilterItems(
    items.companies
  );
  const [selectedLocations, onLocationsChange, clearLocations] = useFilterItems(
    items.locations
  );

  return (
    <div className="fixed inset-0  bg-white z-50 md:hidden max-h-screen">
      <div
        className="w-full h-14 flex justify-between items-center py-3 px-4
              border-b border-dropDownBorder border-solid"
      >
        <h2 className="text-2xl">Filters</h2>
        <img
          src={close}
          alt="close"
          className="cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="p-4 flex flex-col flex-no-wrap h-commonFilter overflow-y-scroll ">
        <h4 className="font-bold"> Department</h4>
        <FilterItemsList
          items={items.departments}
          selectedItems={selectedDepartments}
          onChange={onDepartmentsChange}
        />
        <h4 className="font-bold"> Company</h4>
        <FilterItemsList
          items={items.companies}
          selectedItems={selectedCompanies}
          onChange={onCompaniesChange}
        />
        <h4 className="font-bold"> Location </h4>
        <FilterItemsList
          items={items.locations}
          selectedItems={selectedLocations}
          onChange={onLocationsChange}
        />
      </div>
      <div className="absolute bottom-0 flex h-16 w-full items-center justify-around">
        <Button
          secondary={true}
          onClick={() => {
            clearDepartments();
            clearCompanies();
            clearLocations();
          }}
          className="border-primary"
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            onApplySelection({
              departments: selectedDepartments,
              companies: selectedCompanies,
              locations: selectedLocations,
            });
            onClose();
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default CommonFilter;
