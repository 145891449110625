import React from "react";

import {Link} from "gatsby";
import Img from "gatsby-image";
import {IJobs} from "../../models/jobs";
import Button from "../button";

const rightIcon = require("../../images/right.svg") as string;
const JobListItemDesktop: React.FC<{
  job: IJobs;
  displayCompany?: boolean;
}> = ({job, displayCompany = true}) => (
  <div
    className={`job-row hidden md:flex items-center border-b-2 border-solid border-primary p-8
    hover:bg-primary hover:text-white`}
    key={job.id}
  >
    {displayCompany && (
      <Link className="mr-8" to={`/portfolio/${job.startup.slug}`}>
        {/* <Img
          fluid={job.startup.logo.childImageSharp.fluid}
          className="w-16 h-16 cursor-pointer rounded-lg"
        /> */}

        <img
          src={job.startup.logo.publicURL}
          className="w-16 h-16 cursor-pointer rounded-lg object-contain"
        />
      </Link>
    )}
    <div className="flex flex-col w-4/12">
      <span className="font-bold">{job.title}</span>
      {displayCompany ? (
        <span>{job.startup.name}</span>
      ) : (
        <div> {job.department.name}</div>
      )}
    </div>
    <div className="w-1/4"> {displayCompany ? job.department.name : ""}</div>
    <div className="w-1/4">
      {`${job.location.location}${job.remote ? "/Remote" : ""}`}
    </div>
    {displayCompany ? (
      <div className="w-1/12 navigate hidden text-white">
        {" "}
        <a href={job.learnMoreLink} target="_blank">
          <div className="h-10 w-10 border-2 border-solid border-white flex  items-center justify-center">
            <img src={rightIcon} alt="right" />
          </div>
        </a>
      </div>
    ) : (
      <a href={job.learnMoreLink} target="_blank">
        {" "}
        <Button className="learn-more"> Learn More</Button>{" "}
      </a>
    )}
  </div>
);
export default JobListItemDesktop;
