import React from "react";

import {Link} from "gatsby";
import Img from "gatsby-image";
import {IJobs} from "../../models/jobs";

const rightIcon = require("../../images/right-orange.svg") as string;
const JobListItemMobile: React.FC<{job: IJobs; displayCompany?: boolean}> = ({
  job,
  displayCompany = true,
}) => (
  <div
    className="grid grid-cols-jobsListSmall md:hidden border-b-2 border-solid border-primary py-5 md:p-8"
    key={job.id}
  >
    <div className="flex flex-col">
      <span className="font-bold">{job.title}</span>
      <div> {job.department.name}</div>
      {displayCompany && <span className="text-gray">{job.startup.name}</span>}
      <div className="text-gray">
        {`${job.location.location}${job.remote ? "/Remote" : ""}`}
      </div>
    </div>

    <div
      className={`flex flex-col ${
        displayCompany ? "justify-between" : "justify-end pb-2"
      } relative`}
    >
      {displayCompany && (
        <Link to={`/portfolio/${job.startup.slug}`}>
          {/* <Img
            fluid={job.startup.logo.childImageSharp.fluid}
            className="w-10 h-10 cursor-pointer rounded-lg"
          /> */}
          <img
            src={job.startup.logo.publicURL}
            alt={job.startup.name}
            className="w-10 h-10 cursor-pointer rounded-lg object-contain"
          />
        </Link>
      )}
      <div className="self-end mt-2">
        {" "}
        <a href={job.learnMoreLink} target="_blank">
          <div className="h-8 w-8 border-2 border-solid border-primary flex  items-center justify-center">
            <img src={rightIcon} alt="right" />
          </div>
        </a>
      </div>
    </div>
  </div>
);
export default JobListItemMobile;
