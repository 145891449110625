import React from "react";
import { IAllItems, ISelectedItems } from "../../models/jobs";
import CommonFilter from "./jobs-common-filter";
import DropDown from "../filter-dropdown";
import { IFilterItem } from "../../models/common";

const Box = ({
  className = "",
  text,

  onClick,
  selected = false,
  specificFilterItems = [],
  allFilterItems = { departments: [], companies: [], locations: [] },
  closeBox,
  onApplySelection,
  onCommonFilterApplySelection,
}: {
  className?: string;
  text: string;

  onClick: () => any;
  selected?: boolean;
  specificFilterItems?: IFilterItem[];
  allFilterItems?: IAllItems;
  onApplySelection?: (items: string[]) => void;
  onCommonFilterApplySelection?: (items: ISelectedItems) => void;
  closeBox?: () => void;
}) => {
  const selectedCount = specificFilterItems.filter((item) => item.isSelected)
    .length;
  return (
    <div className="relative w-full max-w-md mx-auto lg:mx-0">
      <div
        onClick={onClick}
        className={` h-12  flex 
      items-center justify-center border-2 border-primary border-solid my-1 mx-auto cursor-pointer
      ${selected ? "bg-primary text-white" : ""} ${className}`}
      >
        <span className="text-base md:text-2xl font-bold">{text}</span>
        <div
          className={`bg-primary text-white rounded-full w-6 h-6 flex items-center justify-center ml-2 ${
            !selected && selectedCount > 0 ? "" : "hidden"
          }`}
        >
          {" "}
          {selectedCount}{" "}
        </div>
        <span
          className={`${
            selected ? "text-white transform rotate-180" : "text-secondary"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`w-4 h-4 md:w-6 md:h-6 ${selected ? "mr-2" : "ml-2"}`}
            viewBox="0 0 24 24"
          >
            <path
              className="fill-current"
              fillRule="evenodd"
              d="M5.672 4.004L8.496 1.172 19.328 11.975 8.504 22.828 5.672 20.004 13.67 11.982z"
              transform="rotate(90 12.5 12)"
            />
          </svg>
        </span>
      </div>

      {selected ? (
        <>
          <DropDown
            items={specificFilterItems}
            onApplySelection={onApplySelection}
            onClose={closeBox}
          />
          <CommonFilter
            items={allFilterItems}
            onApplySelection={(selectedItems) =>
              onCommonFilterApplySelection(selectedItems)
            }
            onClose={closeBox}
          />
        </>
      ) : null}
    </div>
  );
};

export default Box;
